import { z } from 'astro/zod';

export const CreItemAttributesSchema = z.object({
  name: z.string(),
  role: z.string(),
  linkedin: z.string(),
  order: z.number()
});

export const CrewItemSchema = z.object({
  id: z.number(),
  attributes: CreItemAttributesSchema
});

export type CrewItemSchemaType = z.infer<typeof CrewItemSchema>;
